import React from "react";
import ReactDOM from "react-dom/client";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./redux/store";
import "./index.css";
import App from "./App";
import { AuthContextProvider } from "./redux/AuthContext";
import { DataProvider } from "./CustomHooks/DataProvider"; // Import DataProvider

// Add the why-did-you-render setup here
if (process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <DataProvider>
        <AuthContextProvider>
          <App />
        </AuthContextProvider>
      </DataProvider>
    </PersistGate>
  </Provider>
);
