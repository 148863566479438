import React, { createContext, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectIsLoggedIn, selectUserID } from "./IchthusSlice";
import { useData } from "../CustomHooks/DataProvider";

const INITIAL_STATE = {
  currentUser: null,
};

export const AuthContext = createContext(INITIAL_STATE);

export const AuthContextProvider = ({ children }) => {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const userID = useSelector(selectUserID);
  const dispatch = useDispatch();

  // Ensure `useData()` doesn't break if undefined
  const { data = {}, loadingCollection } = useData() || {
    loadingCollection: true,
  };

  // Memoize UsersData to prevent unnecessary re-renders
  const UsersData = useMemo(() => {
    return Array.isArray(data?.user) ? data.user : [];
  }, [data?.user]);

  // Find the current user from UsersData based on userID
  const currentUser = useMemo(() => {
    return (
      !loadingCollection &&
      UsersData.length > 0 &&
      UsersData.find((user) => user.id === userID)
    );
  }, [userID, UsersData, loadingCollection]);

  // Render loading if still fetching data
  if (loadingCollection) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider value={{ isLoggedIn, currentUser, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
