import { openDB } from "idb";

const DB_NAME = "IchthusDB";
const STORE_NAME = "collections";

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME);
    }
  },
});

// Save a collection to IndexedDB
export const saveCollectionToDB = async (collectionName, data) => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, "readwrite");
  const store = tx.objectStore(STORE_NAME);
  await store.put(data, collectionName);
  await tx.done;
};

// Get a collection from IndexedDB
export const getCollectionFromDB = async (collectionName) => {
  const db = await dbPromise;
  return db.get(STORE_NAME, collectionName);
};

// Delete a collection from IndexedDB
export const deleteCollectionFromDB = async (collectionName) => {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, "readwrite");
  await tx.objectStore(STORE_NAME).delete(collectionName);
  await tx.done;
};
