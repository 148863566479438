import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { doc, updateDoc } from "firebase/firestore";
import { ToastContainer, toast } from "react-toastify";
import { db } from "../../firebase/config";
import { useData } from "../../CustomHooks/DataProvider";

const SuccessPayment = () => {
  const navigate = useNavigate();
  const userID = useSelector((state) => state.orebiReducer.userID);
  const { data, refetchCollection, loadingCollection } = useData();
  const orders = data?.ordered;

  useEffect(() => {
    // Refetch the "ordered" collection
    refetchCollection("ordered");

    const updateCoupons = async () => {
      if (orders && userID) {
        const userOrders = orders.find((order) => order.id === userID);

        if (userOrders && userOrders.orders) {
          // Filter orders with status "Paid" and with a valid appliedCouponId
          const paidOrdersWithCoupons = userOrders.orders.filter(
            (order) => order.status === "Paid" && order.appliedCouponId
          );

          for (const paidOrder of paidOrdersWithCoupons) {
            try {
              const couponRef = doc(db, "coupons", paidOrder.appliedCouponId);
              await updateDoc(couponRef, { status: "Used" });
              toast.success(
                `Coupon ${paidOrder.appliedCouponId} marked as used!`
              );
            } catch (error) {
              console.error(
                `Error updating coupon ${paidOrder.appliedCouponId}:`,
                error
              );
              toast.error(
                `Failed to update coupon ${paidOrder.appliedCouponId}.`
              );
            }
          }
        }
      }
    };

    updateCoupons();

    // Set a timeout to redirect after 5 seconds
    const timeout = setTimeout(() => {
      navigate("/order-history");
    }, 5000);

    return () => clearTimeout(timeout); // Cleanup timeout on component unmount
  }, [orders, userID, refetchCollection, navigate]);

  if (loadingCollection) {
    return <div className="text-center py-10">Loading...</div>;
  }

  return (
    <div className="payment-success-container flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="max-w-lg bg-white shadow-md rounded-lg p-8 text-center">
        <h1 className="text-3xl font-bold text-green-600">
          Payment Successful!
        </h1>
        <p className="mt-4 text-lg text-gray-700">
          Thank you for your payment. Your transaction was completed
          successfully.
        </p>
        <p className="mt-2 text-sm text-gray-500">
          Redirecting to your order history in 5 seconds...
        </p>
        <button
          onClick={() => navigate("/order-history")}
          className="mt-6 px-6 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition duration-300"
        >
          View Order History
        </button>
      </div>
    </div>
  );
};

export default SuccessPayment;
