import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
} from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase/config";
import {
  saveCollectionToDB,
  getCollectionFromDB,
} from "../CustomHooks/indexedDB";

const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingCollection, setLoadingCollection] = useState(null);

  // Collections to fetch
  const collections = useMemo(
    () => [
      "Shippingfees",
      "banner",
      "bannerBottom",
      "brand",
      "category",
      "contacts",
      "coupons",
      "featuredProducts",
      "features",
      "history",
      "logos",
      "ordered",
      "productImages",
      "products",
      "promises",
      "serial",
      "stores",
      "user",
      "yearProduct",
    ],
    []
  );

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      const fetchPromises = collections.map(async (collectionName) => {
        const cachedData = await getCollectionFromDB(collectionName);
        if (cachedData) {
          console.log(`🟢 Using IndexedDB data for ${collectionName}`);
          return { collectionName, data: cachedData, fresh: false };
        }

        console.log(`🔄 Fetching data from Firestore for ${collectionName}...`);
        try {
          const snapshot = await getDocs(collection(db, collectionName));
          const docs = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          await saveCollectionToDB(collectionName, docs); // Save to IndexedDB
          console.log(`✅ Saved ${collectionName} to IndexedDB`);

          return { collectionName, data: docs, fresh: true };
        } catch (error) {
          console.error(`❌ Error fetching ${collectionName}:`, error.message);
          return { collectionName, data: [], fresh: false };
        }
      });

      const results = await Promise.allSettled(fetchPromises);

      // Process fetched data
      const newData = {};
      results.forEach((result) => {
        if (result.status === "fulfilled") {
          const { collectionName, data } = result.value;
          newData[collectionName] = data;
        }
      });

      setData(newData);
      setLoading(false);
    };

    fetchData();
  }, [collections]);

  /**
   * Function to manually refetch a collection (Firestore → IndexedDB → State)
   */
  const refetchCollection = async (collectionName) => {
    setLoadingCollection(collectionName);
    console.log(`🔄 Refetching ${collectionName} from Firestore...`);

    try {
      const snapshot = await getDocs(collection(db, collectionName));
      const fetchedData = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      await saveCollectionToDB(collectionName, fetchedData); // Update IndexedDB
      console.log(`✅ Updated ${collectionName} in IndexedDB`);

      setData((prevData) => ({
        ...prevData,
        [collectionName]: fetchedData,
      }));
    } catch (error) {
      console.error(`❌ Error refetching ${collectionName}:`, error.message);
    } finally {
      setLoadingCollection(null);
    }
  };

  return (
    <DataContext.Provider
      value={{ data, loading, refetchCollection, loadingCollection }}
    >
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => useContext(DataContext);
